import {
    Object3D,
    Box3,
    ColorRepresentation,
    BoxHelper,
    GridHelper,
    SphereGeometry,
    BoxGeometry,
    MeshStandardMaterialParameters,
    MeshStandardMaterial,
    MeshPhongMaterialParameters,
    MeshPhongMaterial,
    BufferGeometry,
    Material,
    Mesh,
    Color,
    AmbientLight,
    Vector3,
    DirectionalLight,
    PlaneGeometry,
    Group,
} from "three";

export const Helper = {
    createBox(element: Object3D) {
        const box = new Box3();

        box.setFromObject(element);
        return box;
    },
    createBoxHelper(object: Object3D<Event>|Group, color?: ColorRepresentation | undefined): BoxHelper {
        return new BoxHelper(object, color);
    },
    createGrid: function (options: GridOPtions) {
        const { size, divisons, color1, color2 } = { ...options };

        if (!color1 || !color2) {
            console.warn("Color no defined for grid");
        }

        const grid = new GridHelper(size, divisons, color1, color2);
        grid.name = "gridHelper";

        return grid;
    },
    createSphereGeometry(radius: number, widthSegments: number = 100) {
        return new SphereGeometry(radius, widthSegments, widthSegments / 2);
    },
    createBoxGeometry(conf: BoxGeometryOptions) {
        return new BoxGeometry(conf.width, conf.height, conf.depth);
    },
    createPlaneGeometry(conf: { width?: number; height?: number; widthSegments?: number; heightSegments?: number }) {
        const { width, height, widthSegments, heightSegments } = conf;
        return new PlaneGeometry(width, height, widthSegments, heightSegments);
    },
    createMeshStandardMaterial(options: MeshStandardMaterialParameters) {
        return new MeshStandardMaterial(options);
    },
    createMeshPhongMaterial(options: MeshPhongMaterialParameters) {
        return new MeshPhongMaterial(options);
    },
    createMesh(geometry: BufferGeometry, material: Material | Material[]) {
        return new Mesh(geometry, material);
    },
    createAmbientLight(color: Color, intensity: number) {
        return new AmbientLight(color, intensity);
    },
    createDirectionalLight(color?: Color, intensity?: number, position?: Vector3) {
        const directionalLight = new DirectionalLight(color, intensity);
        const newPosition = position ?? directionalLight.position;
        directionalLight.position.copy(newPosition);
        return directionalLight;
    },
    generateRandomId(length = 24, charException = new Array<string>()): string {
        const charOptions = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_";
        const newRandom = new Set();

        try {
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * (charOptions.length - 0 + 1)) + 0;
                const newChar = charOptions[randomIndex];

                if (!charException.includes(newChar) && !newRandom.has(newChar)) {
                    newRandom.add(newChar);
                } else {
                    i--;
                }
            }
        } catch (error) {
            console.error(error);
        }

        return Array.from(newRandom).join("");
    },
};

export type BoxGeometryOptions = {
    width: number | undefined;
    height: number | undefined;
    depth: number | undefined;
    widthSegments?: number | undefined;
    heightSegments?: number | undefined;
    depthSegments?: number | undefined;
};
type GridOPtions = {
    size: number;
    divisons: number;
    color1?: number;
    color2?: number;
};
