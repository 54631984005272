
import { app } from '@/main';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SceneComponent',
    data() {
        return {
            clickCounter: 0
        }
    },
    mounted() {
        app.init('#render')
    }
});
