import { AnimationMixer, AnimationAction, SkeletonHelper, AnimationClip } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import EventManager from "../mixin/EventManager";
import ModelLoader from "./ModelLoader";

export interface AnimatedmaterialEvents extends ErrorEvents {
    "useless:no-action": () => void;
}

interface ErrorEvents {
    "error:load-gltf": (error: any) => void;
    "error:skeleton-creation": (error: any) => void;
    "error:mixer-creation": (error: any) => void;
    "error:reading-animations": (error: any) => void;
}

export default class AnimatedModel extends EventManager<AnimatedmaterialEvents> {
    public mixer!: AnimationMixer;
    public actions!: Map<string, AnimationAction>;
    public animations!: Map<string, AnimationClip>;
    private modelLoader: ModelLoader;
    public gltf!: GLTF;
    public skeleton!: SkeletonHelper;
    public deltaTime: number;

    public startAction!: AnimationAction;
    public endAction!: AnimationAction;

    constructor(_gltfUrl: string, deltaTime: number) {
        super();
        this.modelLoader = new ModelLoader();
        this.deltaTime = deltaTime;
        this.actions = new Map();
        this.animations = new Map();
    }

    public update() {
        this.mixer.update(this.deltaTime);
    }

    public getAction(name: string) {
        return this.actions.get(name);
    }

    public async init(gltfUrl: string) {
        try {
            this.gltf = await this.modelLoader.readGltf(gltfUrl);
        } catch (error) {
            this.dispatch("error:load-gltf", error);
        }

        try {
            this.skeleton = new SkeletonHelper(this.gltf.scene);
            this.skeleton.visible = true;
        } catch (error) {
            this.dispatch("error:skeleton-creation", error);
        }

        try {
            this.mixer = new AnimationMixer(this.gltf.scene);
        } catch (error) {
            this.dispatch("error:mixer-creation", error);
        }
        try {
            this.gltf.animations.forEach((animation) => {
                this.animations.set(animation.name, animation);
                this.actions.set(animation.name, this.mixer.clipAction(animation));
            });
        } catch (error) {
            this.dispatch("error:reading-animations", error);
        }

        this.events();
    }

    private events() {
        /* this.mixer.addEventListener("loop", (e) => {
            if (this.startAction && this.endAction && this.startAction !== this.endAction) {
                console.log(e);
            }
        }); */
    }

    public crossFade(newEndAction: AnimationAction, duration: number) {
        if (newEndAction !== this.startAction) {
            this.endAction = newEndAction;
            this.unPauseAllActions();
            this.endAction.enabled = true;
            this.endAction.setEffectiveTimeScale(1);
            this.endAction.setEffectiveWeight(1);
            this.endAction.play();

            this.endAction.time = 0;
            this.startAction.crossFadeTo(this.endAction, duration, true);

            this.startAction = this.endAction;
        }
    }

    unPauseAllActions() {
        this.actions.forEach((action) => {
            action.paused = false;
        });
    }
    pauseAllActions() {
        this.actions.forEach((action) => {
            action.paused = true;
        });
    }
}
