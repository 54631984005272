import * as THREE from "three";
import {Vector3, WebGLRenderer} from "three";
import AbstractManager from "@/itw-core/AbstractManager";
import {MathUtils} from "@/itw-core/utils/MathUtils";
import ControlsManager from "@/itw-core/ControlsManager";
import RayCastManager from "./RayCastManager";

export default class CameraManager extends AbstractManager {
    public cameras: Array<THREE.PerspectiveCamera> = [];
    /**
     * Viewport of the canvas, not from renderer
     */
        //@ts-ignore
    public viewport: HTMLElement;
    public autoCenter: boolean = true;
    //@ts-ignore
    public renderer: WebGLRenderer;
    // @ts-ignore
    public activeCamera: THREE.Camera;
    public rayCastManager!: RayCastManager;
    /**
     * Camera distance multiplier
     * @private number
     */
    private delta = 1.2;
    private controlsManager: ControlsManager;

    constructor(controlsManager: ControlsManager) {
        super();
        this.controlsManager = controlsManager;
        this.rayCastManager = new RayCastManager();
    }

    addPerspectiveCamera(options: PerspectiveCameraOptions, activeCamera: boolean = true) {
        const {fov, autoCenter, aspect, near, far, position} = Object.assign(defaultPerspectiveCameraOptions, options);
        const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        // @ts-ignore
        camera.position.copy(position);
        camera.name = "Camera #" + this.cameras.length;
        camera.lookAt(new Vector3(0, 0, 0));
        this.cameras.push(camera);
        this.controlsManager.attachToCamera(camera);
        if (activeCamera) {
            this.setActiveCamera(camera);
            this.autoCenter = autoCenter ?? true;
        }
    }

    getName(): string {
        return "CameraManager";
    }

    initialize(options: CameraManagerInitializeOptions): void {
        this.renderer = options.renderer;
        this.viewport = options.viewport;
        this.addPerspectiveCamera(options.cameraOptions ?? defaultPerspectiveCameraOptions);
        this.updateAspectRatio();
        this.addListeners();
        this.rayCastManager.init(this.getActiveCamera(), this.viewport as HTMLDivElement);
    }

    updateAspectRatio() {
        const viewport = this.viewport;
        const camera = this.getActiveCamera() as THREE.PerspectiveCamera;
        camera.aspect = viewport.offsetWidth / viewport.offsetHeight;
        this.renderer.setSize(viewport.offsetWidth, viewport.offsetHeight);
        camera.updateProjectionMatrix();
    }

    addListeners() {
        window.addEventListener("resize", () => {
            this.updateAspectRatio();
        }, false);
    }

    getActiveCamera(): THREE.Camera {
        return this.activeCamera;
    }

    /**
     * Sets the camera as active camera enabling associated OrbitControls. Disables all others OrbitControls
     * @param camera
     */
    setActiveCamera(camera: THREE.Camera) {
        this.activeCamera = camera;
        this.controlsManager.orbitControls.forEach((orbitControls, currentCamera) => {
            orbitControls.enabled = false;
            if (currentCamera.name.toLowerCase() === this.activeCamera.name.toLowerCase() || this.activeCamera.uuid === currentCamera.uuid) {
                orbitControls.enabled = true;
            }
        });
        this.updateAspectRatio();
    }

    getFov() {
        const perspectiveCamera = this.getActiveCamera() as THREE.PerspectiveCamera;
        return perspectiveCamera.fov;
    }

    getAspect() {
        const perspectiveCamera = this.getActiveCamera() as THREE.PerspectiveCamera;
        return perspectiveCamera.aspect;
    }

    centerInObjectPerspective(element: THREE.Object3D) {
        const {bounds} = MathUtils.getBoundingSize(element);
        const camera = this.getActiveCamera() as THREE.PerspectiveCamera;
        camera.position.x = bounds.max.x * 1.8;
        camera.position.y = bounds.max.y * 1.8;
        camera.position.z = bounds.max.z * 1.8;
    }

    centerInObject(element: THREE.Object3D) {
        const coor = MathUtils.cameraDistanceFromObject(element, this.getFov(), this.getAspect());
        const camera = this.getActiveCamera() as THREE.PerspectiveCamera;
        camera.position.x = coor.x;
        camera.position.y = coor.y;
        camera.position.z = coor.z;
    }
}
export type PerspectiveCameraOptions = {
    fov?: number; aspect?: number; near?: number; far?: number; autoCenter?: boolean; position?: THREE.Vector3;
};
export type CameraManagerInitializeOptions = {
    renderer: WebGLRenderer; viewport: HTMLElement; cameraOptions?: PerspectiveCameraOptions;
};
const defaultPerspectiveCameraOptions: PerspectiveCameraOptions = {
    fov: 50, aspect: 1, near: 0.1, far: 200000, autoCenter: true, position: new THREE.Vector3(0, 4, 25),
};
