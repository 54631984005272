import { basicListener, defaultListener } from "./types/DataTypes";
import { EventList } from "./types/EventManager";
import {Helper} from "@/itw-core/utils/Helper";

export default class EventManager<val extends basicListener | defaultListener = basicListener | defaultListener> {
    /**
     * List of all specific events assigned
     */
    public eventList: EventList<val>;
    /**
     * List of all events that are dispatched always
     */
    public onAnyEvents: Map<string, (...args: any) => void>;

    constructor() {
        this.eventList = {};
        this.onAnyEvents = new Map();
    }

    private _generateEventArray<K extends keyof val>(key: K) {
        this.eventList[key] = this.eventList[key] ?? new Map();
    }
    private _generateRandomId(length = 24, charException = new Array<string>()): string {
       return Helper.generateRandomId(length,charException);
    }

    /**
     * Add callback event to the pool.
     * @param eve Event name
     * @param call Callback that will be executed when it is called
     * @returns Self
     */
    on<K extends keyof val>(eve: K, call: val[K]): { id: string; self: EventManager<val> } {
        this._generateEventArray(eve);
        const id = this._generateRandomId();
        this.eventList[eve]?.set(id, call);
        return { id: id, self: this };
    }

    /**
     * Add a new callback to the poll that will be called for every single event
     * @param callback Callback that will be executed when it is called
     */
    onAny(callback: (...args: any) => void): { id: string; self: EventManager<val> } {
        const id = this._generateRandomId();
        this.onAnyEvents.set(id, callback);
        return { id: id, self: this };
    }

    /**
     * Dispatch all events with that name.
     * @param eve Event name
     * @param val Value/s needed for the event
     * @returns Self
     */
    dispatch<K extends keyof val>(eve: K, ...val: Parameters<val[K]>): EventManager<val> {
        //specific
        this._generateEventArray(eve);
        this.eventList[eve]?.forEach((event) => {
            event(...val);
        });
        //onAny
        this.onAnyEvents.forEach((callbak) => {
            callbak(...val);
        });
        return this;
    }

    /**
     * Delete all events assigned to one type.
     * @param event Event name
     * @returns Self
     */
    clearEvents<K extends keyof val>(event: K): EventManager<val> {
        this.eventList[event] = new Map();
        return this;
    }

    /**
     * Delete all events assigned, on and onAny events
     */
    clearAllEvents(): EventManager<val> {
        this.eventList = {};
        this.onAnyEvents.clear();
        return this
    }

    /**
     * Delete an exact event from the pool.
     * @param eventId Id of the event assigned
     * @returns Self
     */
    clearEvent(eventId: string): EventManager<val> {
        for (const key in Object.keys(this.eventList)) {
            this.eventList[key]?.forEach((event) => {
                event.delete(eventId);
            });
        }
        this.onAnyEvents.delete(eventId);
        return this;
    }
}