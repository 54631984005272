import * as THREE from "three";
import AbstractManager from "@/itw-core/AbstractManager";

export default class RenderManager extends AbstractManager{
    public renderer: THREE.WebGLRenderer;
    public viewport: HTMLElement;

    constructor(domElement: HTMLElement, antialias: boolean = true) {
        super();
        const {offsetWidth: width,offsetHeight: height} = domElement;
        this.renderer = new THREE.WebGLRenderer({ antialias: antialias,alpha: true });
        this.renderer.shadowMap.enabled = true
        this.renderer.setSize(width, height);
        this.viewport = this.renderer.domElement;
    }
    initialize(): void {
        this.viewport.appendChild(this.renderer.domElement);
    }
    getName(): string {
        return "RenderManager";
    }


}