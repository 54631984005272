import {IEvent} from "@/itw-core/mixin/IEvent";

export default class EventDispatcher implements IEvent{
    private _eventHandlers = {};

    addEventListener(type: string, handler: any): void {
        // @ts-ignore
        this._eventHandlers[type] = this._eventHandlers[type] || [];
        // @ts-ignore
        this._eventHandlers[type].push(handler);
    }

    dispatch(type: string, args: any): void {
        // @ts-ignore
        const handlers = this._eventHandlers[type];
        if (handlers) {
            for (let i = 0; i < handlers.length; i++) {
                this.dispatchEvent(args, handlers[i]);
            }
        }
    }

    dispatchEvent(args: any, handler: any): void {
        handler(args);
    }

    removeAllListeners(type: string): void {
        let a = 1;
        a++;
    }

    removeEventListener(type: string, theHandler: any): void {
        let a = 1;
        a++;
    }
    on(type: string, handler: any): void {
        this.addEventListener(type, handler);
    }
}