import {Camera, Intersection, Object3D, Raycaster, Vector2, Vector3} from "three";
import AbstractManager from "@/itw-core/AbstractManager";

export default class Ray extends AbstractManager {

    private _raycast: Raycaster;
    private _pointerCoor: Vector2;
    private _listening: boolean;
    private _viewPort: HTMLElement

    constructor(viewPort: HTMLElement) {
        super();
        if (!viewPort)throw new Error('RealBlendGine Error - no viewport given to the Ray')
        console.log(viewPort)
        this._viewPort = viewPort
        this._raycast = new Raycaster();
        this._pointerCoor = new Vector2(-2, -2);
        this._listening = false;
    }

    public initialize(): void {
        if (!this._listening) {
            // window.addEventListener("mousemove", this.onMouseMove.bind(this));
            this._viewPort.addEventListener("mousemove", this.onMouseMove.bind(this));
            this._listening = true;
        }
    }

    private onMouseMove(event: MouseEvent) {
        this.setPointerCoor = this.calculateCoorFromEvent(event);
    }

    //not implemented
    /* public stopListening() {
        window.removeEventListener("mousemove", this.onMouseMove.bind(this));
        this._listening = false;
    } */

    public calculateCoorFromEvent(event: MouseEvent): Vector2 {
        return new Vector2((event.offsetX / this.viewPort.offsetWidth) * 2 - 1, -(event.offsetY / this.viewPort.offsetHeight) * 2 + 1);
        // return new Vector2((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
    }

    public set(origin: Vector3, direction: Vector3) {
        this._raycast.set(origin, direction);
    }

    public setFromCamera(camera: Camera, coor: Vector2 = this._pointerCoor) {
        this._raycast.setFromCamera(coor, camera);
    }

    /**
     * Calculates intersection with the array of objects and the ray, returns an array of Intersection
     * @param objsArray Array of objects to calculate intersection with the ray.
     * @param optional
     * @param optional.recursive Set if recursive to the objects, default true
     * @param optional.arrayTarget Array in wich be saved the result of the intersection, if not given will create a new one
     * @returns Array<Intersection>
     */
    public interObjects<TIntersected extends Object3D>(objsArray: Object3D[], optional: IntersectOptionalParameters<TIntersected> = {
        recursive: true, arrayTarget: undefined,
    }) {
        let {recursive} = optional;
        const {arrayTarget} = optional;
        recursive = recursive ?? true;
        if (arrayTarget) arrayTarget.length = 0;

        return this._raycast.intersectObjects(objsArray, recursive, arrayTarget);
    }

    /**
     * Checks all intersection between the ray and the object with or without the descendants. Intersections are returned sorted by distance, closest first.
     * @param obj The object to check for intersection with the ray.
     * @param optional
     * @param optional.recursive If true, it also checks all descendants. Otherwise, it only checks intersection with the object. Default is true.
     * @param optional.optionalTarget (optional) target to set the result. Otherwise, a new Array is instantiated.
     * @returns Array<Intersection>
     */
    public interObject<TIntersected extends Object3D>(obj: Object3D, optional: IntersectOptionalParameters<TIntersected> = {
        recursive: true, arrayTarget: undefined,
    }) {
        let {recursive} = optional;
        const {arrayTarget} = optional;
        recursive = recursive ?? true;
        if (arrayTarget) arrayTarget.length = 0;
        return this._raycast.intersectObject(obj, recursive, arrayTarget);
    }

    public set setPointerCoor(newCoor: Vector2) {
        this._pointerCoor.x = newCoor.x;
        this._pointerCoor.y = newCoor.y;
    }

    public get getPointerCoor() {
        return this._pointerCoor;
    }

    get viewPort(): HTMLElement {
        return this._viewPort;
    }

    getName(): string {
        return 'Ray';
    }
}

interface IntersectOptionalParameters<TIntersected extends Object3D> {
    recursive?: boolean;
    arrayTarget?: Array<Intersection<TIntersected>>;
}