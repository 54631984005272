import {Vector2, Vector3} from "three";
import * as THREE from "three";
import AbstractManager from "@/itw-core/AbstractManager";

export class GeometryManager extends AbstractManager{


    public uvGenerator = {
        // @ts-ignore
        generateTopUV: function (geometry, vertices, indexA, indexB, indexC) {

            const box = new THREE.Box3().setFromArray(vertices);
            const size = new THREE.Vector3();
            box.getSize(size);

            const a_x = vertices[indexA * 3];
            const a_y = vertices[indexA * 3 + 1];
            const b_x = vertices[indexB * 3];
            const b_y = vertices[indexB * 3 + 1];
            const c_x = vertices[indexC * 3];
            const c_y = vertices[indexC * 3 + 1];

            return [
                new THREE.Vector2((a_x - box.min.x) / size.x, (a_y - box.min.y) / size.y),
                new THREE.Vector2((b_x - box.min.x) / size.x, (b_y - box.min.y) / size.y),
                new THREE.Vector2((c_x - box.min.x) / size.x, (c_y - box.min.y) / size.y)
            ];

        },
        // @ts-ignore
        generateSideWallUV: function (geometry, vertices, indexA, indexB, indexC, indexD) {

            const a_x = vertices[indexA * 3];
            const a_y = vertices[indexA * 3 + 1];
            const a_z = vertices[indexA * 3 + 2];
            const b_x = vertices[indexB * 3];
            const b_y = vertices[indexB * 3 + 1];
            const b_z = vertices[indexB * 3 + 2];
            const c_x = vertices[indexC * 3];
            const c_y = vertices[indexC * 3 + 1];
            const c_z = vertices[indexC * 3 + 2];
            const d_x = vertices[indexD * 3];
            const d_y = vertices[indexD * 3 + 1];
            const d_z = vertices[indexD * 3 + 2];

            if (Math.abs(a_y - b_y) < 0.01) {

                return [
                    new THREE.Vector2(a_x, 1 - a_z),
                    new THREE.Vector2(b_x, 1 - b_z),
                    new THREE.Vector2(c_x, 1 - c_z),
                    new THREE.Vector2(d_x, 1 - d_z)
                ];

            } else {

                return [
                    new THREE.Vector2(a_y, 1 - a_z),
                    new THREE.Vector2(b_y, 1 - b_z),
                    new THREE.Vector2(c_y, 1 - c_z),
                    new THREE.Vector2(d_y, 1 - d_z)
                ];

            }

        }
    }

    sortVectorsFromDistance(points: Array<Vector2>): Array<Vector2> {
        console.log("Points Length: " + points.length);
        /*let qqq: Array<Vector2> = [];
        points.forEach(item => {
            qqq.push(new Vector2(item.x,item.y));
        })*/
        const sortedPoints: Array<Vector2> = [];
        let centerPoint = points.shift() ?? new Vector2();
        sortedPoints.push(centerPoint);
        do {
            const checkedPoints = [];
            for (let index = 0; index < points.length; index++) {
                const currentPoint = points[index];
                const distance = new Vector2(currentPoint.x, currentPoint.y).distanceTo(centerPoint);
                checkedPoints.push({distance: distance, index: index, coords: currentPoint});
            }
            let closestPoint = {distance: 99999999, index: -1, coords: new Vector2(0, 0)};
            checkedPoints.map(item => {
                if (closestPoint.distance > item.distance) {
                    closestPoint = item;
                }
            });
            // Set the new point as center point for the next search
            centerPoint = points.splice(closestPoint.index, 1)[0];
            // Save the new center point as sorted po   int
            sortedPoints.push(centerPoint);

        } while (points.length > 0);
        return sortedPoints;
    }

    getBounds(points: Array<Vector2>) {
        const max = {x: -99999, y: -99999};
        const min = {x: 99999, y: 99999};
        points.forEach(item => {
            if (max.x < item.x) max.x = item.x
            if (min.x > item.x) min.x = item.x

            if (max.y < item.y) max.y = item.y
            if (min.y > item.y) min.y = item.y
        });
        return {max: max, min: min};
    }

    generateExtraPoints(verticesAtEdge: Array<Vector2>) {
        const max = {x: -99999, y: -99999};
        const min = {x: 99999, y: 99999};
        verticesAtEdge.forEach(item => {
            if (max.x < item.x) max.x = item.x
            if (min.x > item.x) min.x = item.x

            if (max.y < item.y) max.y = item.y
            if (min.y > item.y) min.y = item.y
        });
        for (let index = 0; index < 1; index++) {
            const x = Math.random() * (max.x - min.x) + min.x;
            const y = Math.random() * (max.y - min.y) + min.y;
            verticesAtEdge.push(new Vector2(x, y));
        }
        console.dir("Total points: ", verticesAtEdge);
        return verticesAtEdge;
    }

    createGeometryFromPath(path: Array<Vector2>, hole: Array<Vector2> = [],extrudeBufferGeometryOptions: any = []) {
        const shape = new THREE.Shape(path);
        if (hole.length > 0) {
            const holePath = new THREE.Path(hole);
            shape.holes.push(holePath);
        }
        const defaultOptions = {
            depth: 0.01,
            bevelEnabled: false,
            UVGenerator: this.uvGenerator
        }
        const geometryOptions = {...defaultOptions,...extrudeBufferGeometryOptions};
        const geometry = new THREE.ExtrudeGeometry(shape,geometryOptions);
        return geometry;
    }

    getCenter(points: Array<Vector2>) {
        const bounds = this.getBounds(points);
        const center = new THREE.Vector2((bounds.max.x + bounds.min.x) / 2, (bounds.max.y + bounds.min.y) / 2);
        return center;
    }

    createLine(points: Array<Vector2>, color = 0xCCCCCC) {
        const material = new THREE.LineBasicMaterial({color: color, linewidth: 10});
        const geometry = new THREE.BufferGeometry();
        const transformedPoints: Array<Vector3> = [];
        points.forEach(item => transformedPoints.push(new Vector3(item.x, 0, item.y)));
        geometry.setFromPoints(transformedPoints);
        //geometry.setAttribute('position', new THREE.Float32BufferAttribute(points, 3));
        const line = new THREE.Line(geometry, material);
        return line;
    }

    offsetContour(offset: number, contour: Array<Vector2>) {

        const result = [];

        const offsetBufferAttribute = new THREE.BufferAttribute(new Float32Array([offset, 0, 0]), 3);

        for (let i = 0; i < contour.length; i++) {
            const v1 = new THREE.Vector2().subVectors(contour[i - 1 < 0 ? contour.length - 1 : i - 1], contour[i]);
            const v2 = new THREE.Vector2().subVectors(contour[i + 1 == contour.length ? 0 : i + 1], contour[i]);
            const angle = v2.angle() - v1.angle();
            const halfAngle = angle * 0.5;

            const hA = halfAngle;
            const tA = v2.angle() + Math.PI * 0.5;

            const shift = Math.tan(hA - Math.PI * 0.5);
            const shiftMatrix = new THREE.Matrix4().set(
                1, 0, 0, 0,
                -shift, 1, 0, 0,
                0, 0, 1, 0,
                0, 0, 0, 1
            );


            const tempAngle = tA;
            const rotationMatrix = new THREE.Matrix4().set(
                Math.cos(tempAngle), -Math.sin(tempAngle), 0, 0,
                Math.sin(tempAngle), Math.cos(tempAngle), 0, 0,
                0, 0, 1, 0,
                0, 0, 0, 1
            );

            const translationMatrix = new THREE.Matrix4().set(
                1, 0, 0, contour[i].x,
                0, 1, 0, contour[i].y,
                0, 0, 1, 0,
                0, 0, 0, 1,
            );

            const cloneOffset = offsetBufferAttribute.clone();

            cloneOffset.applyMatrix4(shiftMatrix);
            cloneOffset.applyMatrix4(rotationMatrix);
            cloneOffset.applyMatrix4(translationMatrix);

            result.push(new THREE.Vector2(cloneOffset.getX(0), cloneOffset.getY(0)));
        }


        return result;
    }

    getAngle(a: Vector2, b: Vector2) {
        //θ = cos-1((a · b) / (|a| · |b|))
        const axb = (a.x * b.x) + (a.y * b.y);
        const IaI = Math.sqrt(a.x * a.x + a.y * a.y);
        const IbI = Math.sqrt(b.x * b.x + b.y * b.y);
        // If any of the above sqrt operations are equal to zero means the angle is zero
        if (IaI == 0 || IbI == 0) return 0;
        const angle = 1 / Math.cos((axb / (IaI * IbI)));
        console.log("Points: ", a, b);
        console.log("getAngle: ", axb, IaI, IbI, angle);
        return angle * Math.PI / 180

        /*function toRad(v)
        {
            return v * Math.PI / 180;
        }
        var R = 6371; // km
        var dLat = toRad(b.x-a.x);
        var dLon = toRad(b.y-a.y);
        var lat1 = toRad(b.x);
        var lat2 = toRad(b.x);

        var aa = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(aa), Math.sqrt(1-aa));

        return toRad(c);*/

    }

    getGeoCenter(points: Array<Vector2>) {
        const center = new Vector2(0, 0);
        points.forEach(item => {
            center.x += item.x;
            center.y += item.y;
        });
        center.x = center.x/points.length;
        center.y = center.y/points.length;
        return center;
    }

    getName(): string {
        return "";
    }

    initialize(): void {
    }
}