import * as THREE from "three";
import ControlsManager from "@/itw-core/ControlsManager";
import AbstractManager from "@/itw-core/AbstractManager";
import { Object3D } from "three";
import MaterialManager from "@/itw-core/MaterialManager";
import { MathUtils } from "@/itw-core/utils/MathUtils";
import { GeometryManager } from "@/itw-core/GeometryManager";
import RenderManager from "@/itw-core/RenderManager";
import CameraManager from "@/itw-core/CameraManager";
import { Helper } from "./utils/Helper";
import LightManager from "@/itw-core/LightManager";

export default class SceneManager extends AbstractManager {
    public controlsManager: ControlsManager;
    public materialManager: MaterialManager;
    public geometryManager: GeometryManager;
    public cameraManager: CameraManager;
    public renderManager!: RenderManager;
    public lightManager!: LightManager;
    public scene: THREE.Scene;
    //public sceneElements: Record<string, Array<{ name: string; object3D: THREE.Object3D }>>;
    public sceneElements: Record<string, Array<THREE.Object3D >>;
    public gridEnabled: boolean = false;

    constructor() {
        super();
        this.controlsManager = new ControlsManager();
        this.materialManager = new MaterialManager();
        this.geometryManager = new GeometryManager();
        this.cameraManager = new CameraManager(this.controlsManager);

        this.scene = new THREE.Scene();
        this.sceneElements = {};
    }

    //region AbstractManager[...]
    initialize(args: HTMLElement) {
        this.renderManager = new RenderManager(args);
        this.lightManager = new LightManager(this);
        this.materialManager.initialize();
        this.geometryManager.initialize();
        this.controlsManager.initialize({ domElement: this.renderManager.viewport });
        this.cameraManager.initialize({ renderer: this.renderManager.renderer, viewport: args });
        this.lightManager.initialize();
    }

    /**
     * Generates and adds to scene default objects:
     * - AmbientLight
     */
    generateDefaults() {
        this.add(this.lightManager.ambientLight);
    }
    getName(): string {
        return "SceneManager";
    }

    //endregion
    setSceneBackgroundColor(color: THREE.Color | number) {
        if (typeof color == "number") color = new THREE.Color(color);
        this.scene.background = color;
    }

    add(element: Object3D) {
        this.scene.add(element);
        /* this.sceneElements[element.type] ? "" : (this.sceneElements[element.type] = []);
        this.sceneElements[element.type].push(element); */
        this.sceneElements[element.type] = this.sceneElements[element.type] ?? []
        this.sceneElements[element.type].push(element);

        //element.traverse((child)=>{
        element.children.forEach((child)=>{
            this.sceneElements[child.type] = this.sceneElements[child.type] ?? []
            this.sceneElements[child.type].push(child);
        })

    }

    get(element: string): Object3D | undefined {
        const foundObject = this.find(element);
        return foundObject;
    }

    find(nameOrUuid: string): Object3D | undefined {
        const found = this.filter(nameOrUuid);
        if (found) return found.pop();
        return found;
    }

    filter(nameOrUuid: string): Array<Object3D> {
        const dataReturn: Array<Object3D> = [];

        for (const [k, v] of Object.entries(this.sceneElements)) {
            this.sceneElements[k].forEach((element3d) => {
                //element3d.name == nameOrUuid || element3d.object3D.uuid == nameOrUuid
                element3d.name == nameOrUuid || element3d.uuid == nameOrUuid
                    //? dataReturn.push(element3d.object3D)
                    ? dataReturn.push(element3d)
                    : "";
            });
        }

        return dataReturn;
    }

    changeMaterialColor(color: THREE.Color | number, elementId: string): boolean {
        if (typeof color === "number") color = new THREE.Color(color);
        // Search by name
        const foundObject = this.find(elementId);
        if (!foundObject) return false;
        // foundObject is defined and contains the searched element
        this.materialManager.setColor(color, foundObject);
        return true;
    }

    changeMaterialToObjects(material: THREE.Material) {
        this.sceneElements["Mesh"].forEach((sceneElement) => {
            //@ts-ignore
            sceneElement.object3D.material = material;
        });
    }

    toggleLightsStatus(status: boolean, typeLight: string) {
        this.sceneElements[typeLight].forEach((element) => {
            //@ts-ignore
            status ? (element.object3D.intensity = 1) : (element.object3D.intensity = 0);
            //@ts-ignore
            this.lightManager[typeLight] = status;
        });
    }

    toggleAllGrids(): Array<{ name: string; visible: boolean }> {
        const objectsVisible: Array<{ name: string; visible: boolean }> = [];

        this.sceneElements["GridHelper"].forEach((element) => {
            //element.object3D.visible = !element.object3D.visible;
            element.visible = !element.visible;
            //objectsVisible.push({ name: element.name, visible: element.object3D.visible });
            objectsVisible.push({ name: element.name, visible: element.visible });
        });
        return objectsVisible;
    }

    toggleGrid(nameOrUuid: string) {
        let visible: boolean = false;
        this.sceneElements["GridHelper"].forEach((element) => {
            /* if (element.name === nameOrUuid || element.object3D.uuid === nameOrUuid) {
                element.object3D.visible = !element.object3D.visible;
                visible = element.object3D.visible;
            } */
            if (element.name === nameOrUuid || element.uuid === nameOrUuid) {
                element.visible = !element.visible;
                visible = element.visible;
            }
        });
        return visible;
    }

    setObjectAt(element: Object3D, position: THREE.Vector3) {
        element.position.copy(position);
    }

    centerObject(element: Object3D) {
        this.setObjectAt(element, MathUtils.getCenterRelativeToScene(element));
    }

    addGridByElement(element: Object3D, color1?: number, color2?: number) {
        const size = MathUtils.getSize(element);

        const gridSize = Math.round(Math.max(size.x, size.z)) + 1;

        const grid = Helper.createGrid({
            size: gridSize,
            divisons: gridSize * 2,
            color1: color1,
            color2: color2,
        });

        grid.name = "gridHelper";
        grid.visible = this.gridEnabled;
        this.add(grid);
    }

    //endregion
    toggleVisibility(element: Object3D) {
        element.visible = !element.visible;
    }

    toggle(element: Object3D, property: string) {
        //@ts-ignore
        element[property] = !element[property];
    }
}
