import * as THREE from "three";

export default class RayCastManager {
    private _camera!: THREE.Camera;
    private _pointer: THREE.Vector2;
    private _viewport!: HTMLDivElement;
    public raycaster: THREE.Raycaster;

    constructor() {
        this._pointer = new THREE.Vector2();
        this.raycaster = new THREE.Raycaster();
    }

    init(camera: THREE.Camera, viewport: HTMLDivElement) {
        this._camera = camera;
        this._viewport = viewport;
        this.events();
    }

    newFrame(objects: THREE.Object3D<THREE.Event>[]): THREE.Intersection<THREE.Object3D<THREE.Event>>[] {
        if (objects) {
            this.raycaster.setFromCamera(this._pointer, this._camera);
            return this.raycaster.intersectObjects(objects);
        } else {
            return new Array<THREE.Intersection<THREE.Object3D<THREE.Event>>>();
        }
    }

    clickScene(objects: THREE.Object3D<THREE.Event>[]) {
        if (objects) {
            this.raycaster.setFromCamera(this._pointer, this._camera);
            return this.raycaster.intersectObjects(objects);
        } else {
            return new Array<THREE.Intersection<THREE.Object3D<THREE.Event>>>();
        }
    }

    private events() {
        this._viewport.addEventListener("pointermove", (event) => {
            this.setPointer(event, this._viewport);
        });
    }

    setPointer(event: PointerEvent, container: HTMLDivElement) {
        this._pointer.x = (event.clientX / container.clientWidth) * 2 - 1;
        this._pointer.y = -(event.clientY / container.clientHeight) * 2 + 1;
    }
}
