import {
    AmbientLight,
    AnimationAction,
    AnimationClip,
    AnimationMixer,
    Group,
    Material,
    Mesh,
    MeshStandardMaterial,
    Object3D,
    PlaneGeometry,
    sRGBEncoding,
    Texture,
    Vector3
} from 'three';
//@ts-ignore
import {TWEEN} from 'three/examples/jsm/libs/tween.module.min.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {MovementHelper} from './itw-core/utils/MovementHelper';
import {getTexture} from '@/itw-core/utils/FilesReader';
import {MathUtils} from "@/itw-core/utils/MathUtils";

import ModelLoader from "@/itw-core/classes/ModelLoader";
import EngineManager from "@/itw-core/EngineManager";
import Ray from "@/itw-core/classes/Ray";
import {LoopOnce, LoopPingPong, LoopRepeat} from "three/src/constants";

export default class AppManager {
    public engine: EngineManager;
    private loader: ModelLoader;

    private readonly roomsCenter: Array<Vector3>;
    private actualRoom: number;

    public tween: TWEEN;
    roomsNames = ['Bim', 'Archviz', 'Desarrollos', 'Interactividad', 'Metaverso'];

    private _animations: AnimationController[] = []
    private allGeometries = new Group()
    private firstRoom = new Group()

    private floor2 = {
        floor2Spheres: [] as { animation: AnimationController, model: Object3D }[],
        floor2Buildings: [] as { animation: AnimationController, model: Object3D }[],
        floor2RobotArms: [] as { animation: AnimationController, model: Object3D }[],
        floor2BuildingsMaterials: [] as MeshStandardMaterial[],
        floor2BuildingBase: null as unknown as { animation: AnimationController, model: Object3D },
        floor2RobotArmBase: null as unknown as { animation: AnimationController, model: Object3D },
    }
    private _sphereIndex = 0

    private ray!: Ray

    constructor() {
        this.roomsCenter = [];
        this.actualRoom = 0;
        this.engine = new EngineManager();
        this.loader = new ModelLoader();
    }

    init(container: string) {
        this.engine.initialize(container);
        this.ray = new Ray(this.engine.viewport)
        console.log(this.ray.viewPort)
        console.log(this.ray)
        this.ray.initialize()
        const camera = this.engine.sceneManager.cameraManager.getActiveCamera();
        const controls = this.engine.sceneManager.controlsManager.getByCamera(camera);
        if (!controls) return
        controls.enabled = false
        this.addModel().then(() => {
            document.querySelector('#loader')?.classList.toggle('d-none');
        });
        this.events();
    }

    private setControls(orbitControls: OrbitControls) {
        //camera weight
        orbitControls.enableDamping = true;
        //disable right click
        orbitControls.enablePan = false
        //to de bottom
        orbitControls.maxPolarAngle = Math.PI / 2;
        //to the top
        orbitControls.minPolarAngle = Math.PI / 4;
        //to the left
        orbitControls.minAzimuthAngle = Math.PI * 1.5;
        //to the right
        orbitControls.maxAzimuthAngle = Math.PI * 2;
        //zoom distance
        orbitControls.maxDistance = 40
        orbitControls.minDistance = 10
    }

    private validateRoomIndex() {
        this.actualRoom = this.actualRoom < 0 ? 0 : this.actualRoom;
        this.actualRoom = this.actualRoom > this.roomsCenter.length - 1 ? this.roomsCenter.length - 1 : this.actualRoom;
    }

    private events() {
        this.engine.on('onFrame', () => {
            this._animations.forEach((qqq) => {
                qqq.update(this.engine.delta)
            })
            this.floor2.floor2RobotArms.forEach(({animation}) => animation.update(this.engine.delta))
            this.floor2.floor2Spheres.forEach(({animation}) => animation.update(this.engine.delta))
            if (this.floor2.floor2RobotArmBase) this.floor2.floor2RobotArmBase.animation.update(this.engine.delta)
            if (this.floor2.floor2BuildingBase) this.floor2.floor2BuildingBase.animation.update(this.engine.delta)

            if (this.floor2.floor2BuildingBase) this.floor2.floor2BuildingBase.animation.actions.forEach(action => {

                if (Math.floor(action.time * 30) === 77) {
                    //@ts-ignore
                    this.floor2.floor2BuildingBase.model.children[0].material = this.floor2.floor2BuildingsMaterials[this._sphereIndex]
                }
            })
        })

        this.ray.viewPort.addEventListener('mousemove', () => {
            this.ray.setFromCamera(this.engine.sceneManager.cameraManager.activeCamera)
            const spheres: Object3D[] = []
            this.floor2.floor2Spheres.forEach(sphere => spheres.push(sphere.model))
            const intersection = this.ray.interObjects(spheres).pop()
            console.log(intersection)
            if (!intersection) {
                document.body.style.cursor = ''
            } else {
                document.body.style.cursor = 'pointer'
            }
        })

        this.ray.viewPort.addEventListener('click', () => {
            const spheres: Object3D[] = []
            this.floor2.floor2Spheres.forEach(sphere => spheres.push(sphere.model))
            const intersection = this.ray.interObjects(spheres).pop()

            if (!intersection) return

            this._sphereIndex = 0
            this.floor2.floor2Spheres.forEach((sphere, index) => {

                if (sphere.model.uuid === intersection.object.uuid) this._sphereIndex = index
                this.floor2.floor2RobotArms[index].model.visible = false
            })
            const robotArm = this.floor2.floor2RobotArms[this._sphereIndex]
            const sphere = this.floor2.floor2Spheres[this._sphereIndex]
            robotArm.model.visible = true
            robotArm.animation.playAll()
            sphere.animation.playAll()
            this.floor2.floor2BuildingBase.animation.playAll()
        })

        const start = document.querySelector('#start-button');
        const prev = document.querySelector('#previous-button');
        const info = document.querySelector('#info-button');
        const next = document.querySelector('#next-button');

        if (start && prev && next && info) {
            start.addEventListener('click', () => {
                this.startAction()
            });
            prev.addEventListener('click', () => {
                const actualPosition = this.roomsCenter[this.actualRoom].clone()
                this.actualRoom--;
                this.validateRoomIndex()
                this.moveCamSmooth(this.roomsCenter[this.actualRoom].clone(), actualPosition);
                info.innerHTML = this.roomsNames[this.actualRoom];
            });
            info.addEventListener('click', () => {
            });
            next.addEventListener('click', () => {
                const actualPosition = this.roomsCenter[this.actualRoom].clone()
                this.actualRoom++;
                this.validateRoomIndex()
                this.moveCamSmooth(this.roomsCenter[this.actualRoom].clone(), actualPosition);
                info.innerHTML = this.roomsNames[this.actualRoom];
            });
        }
    }

    private moveCamSmooth(newPositionClone: Vector3, actualPositionClone: Vector3) {
        const camera = this.engine.sceneManager.cameraManager.getActiveCamera();
        const controls = this.engine.sceneManager.controlsManager.getByCamera(camera) as OrbitControls;

        const finalPosition = new Vector3(newPositionClone.x - 2, newPositionClone.y + 1, newPositionClone.z + 2);

        const actualCamPos = camera.position.clone()
        const dir = new Vector3()
        dir.subVectors(actualCamPos, actualPositionClone)
        finalPosition.copy(newPositionClone.clone().add(dir))

        if (controls) {
            controls.enabled = false;
            if (this.tween && this.tween.isPlaying()) {
                this.tween.stop();
                this.tween.end();
            }

            MovementHelper.smoothMoveto({
                from: camera.position, to: finalPosition, duration: 1000, onComplete: () => {
                    controls.enabled = true;
                    this.engine.sceneManager.controlsManager.setControlsTarget(controls, newPositionClone);
                }, onUpdate: () => {
                    const distanceBetweenCameraPositions = camera.position.clone().sub(actualCamPos)
                    const pointToLookAt = actualPositionClone.add(distanceBetweenCameraPositions)
                    camera.lookAt(pointToLookAt)
                    actualCamPos.copy(camera.position.clone())
                },
            });
        }
    }

    private _traverseGroupAndAssignNewMaterial(group: Group, material: Material) {
        group.traverse((element) => {
            const auxVariable = element as Mesh

            if (!auxVariable.material) return
            if (Array.isArray(auxVariable.material)) {
                const newMaterialArray: Material[] = []
                auxVariable.material.forEach(() => {
                    newMaterialArray.push(material)
                })
                auxVariable.material = newMaterialArray
            } else {
                auxVariable.material = material
            }
        })
    }

    async addModel() {
        return new Promise((resolve, reject) => {
            const aLight = new AmbientLight(0xffffff, 1);
            this.engine.sceneManager.add(aLight);

            // const libUrl = ''
            const libUrl = 'https://lib.baboonlab.com'

            const promises: Promise<any>[] = [//Static_Geometries
                this.loader.readFbx(libUrl + '/3Dmodels/Static_Geometries/BaseRed_geo.fbx').then((group: Group) => {
                    return getTexture(libUrl + '/3Dmodels/Static_Geometries/RedBase_COLOR2.png').then((texture: Texture) => {
                        const base: Mesh = group.children[0] as Mesh
                        if (!base) return
                        texture.encoding = sRGBEncoding
                        base.material = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                    }).then(() => {
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Static_Geometries/Floor1_geo.fbx').then((group: Group) => {
                    return getTexture(libUrl + '/3Dmodels/Static_Geometries/Floor1_COLOR.png').then((texture: Texture) => {
                        const geo01: Mesh = group.children[0] as Mesh
                        if (!geo01) return
                        texture.encoding = sRGBEncoding
                        geo01.material = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                    }).then(() => {
                        group.name = 'Floor1_geo'
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Static_Geometries/Floor2_geo.fbx').then((group: Group) => {
                    return getTexture(libUrl + '/3Dmodels/Static_Geometries/Floor2_COLOR.png').then((texture: Texture) => {
                        const geo02: Mesh = group.children[0] as Mesh
                        if (!geo02) return
                        texture.encoding = sRGBEncoding
                        geo02.material = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                    }).then(() => {
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Static_Geometries/Floor3_geo.fbx').then((group: Group) => {
                    return getTexture(libUrl + '/3Dmodels/Static_Geometries/Floor3_COLOR.png').then((texture: Texture) => {
                        const geo03: Mesh = group.children[0] as Mesh
                        if (!geo03) return
                        texture.encoding = sRGBEncoding
                        geo03.material = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                    }).then(() => {
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Static_Geometries/Floor4_geo.fbx').then((group: Group) => {
                    return getTexture(libUrl + '/3Dmodels/Static_Geometries/Floor4_COLOR.png').then((texture: Texture) => {
                        const geo04: Mesh = group.children[0] as Mesh
                        if (!geo04) return
                        texture.encoding = sRGBEncoding
                        geo04.material = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                    }).then(() => {
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Static_Geometries/Floor5_geo.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    return getTexture(libUrl + '/3Dmodels/Static_Geometries/Floor5_COLOR.png').then((texture: Texture) => {
                        const geo05: Mesh = group.children[0] as Mesh
                        if (!geo05) return
                        texture.encoding = sRGBEncoding
                        geo05.material = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                    }).then(() => {
                        return group
                    })
                }), //animated_geometries
                this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor1_Building.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor01_Building_COLOR.png').then((texture: Texture) => {
                        const building: Mesh = group.children[0].children[0] as Mesh
                        if (!building) return
                        texture.encoding = sRGBEncoding
                        building.material = new MeshStandardMaterial({color: 0xffffff, map: texture, transparent: true, opacity: .33})
                    }).then(() => {
                        group.name = 'Floor1_Building'
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor1_BuildingPipes.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor01_BuildingPipes_COLOR.png').then((texture: Texture) => {
                        const pipes: Mesh = group.children[0].children[0] as Mesh
                        if (!pipes) return
                        texture.encoding = sRGBEncoding
                        pipes.material = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                    }).then(() => {
                        group.name = 'Floor1_Building'
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor2_BuildingBASE.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    // this._animations.push(animation)
                    this.floor2.floor2BuildingBase = {model: group, animation}

                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor2_BuildingBaseSphere1_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        this.floor2.floor2BuildingsMaterials[0] = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                    }).then(() => {
                        getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor2_BuildingBaseSphere2_COLOR.png').then((texture: Texture) => {
                            texture.encoding = sRGBEncoding
                            this.floor2.floor2BuildingsMaterials[1] = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                        })
                    }).then(() => {
                        getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor2_BuildingBaseSphere3_COLOR.png').then((texture: Texture) => {
                            const building01: Mesh = group.children[0] as Mesh

                            if (!building01) return
                            texture.encoding = sRGBEncoding
                            const material = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                            building01.material = material
                            this.floor2.floor2BuildingsMaterials[2] = material
                        })
                    }).then(() => {
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor2_Muebles.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor2_Muebles_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        const furniture = group.children[group.children.length - 1].children[0]//@ts-ignore
                        furniture.material = [new MeshStandardMaterial({color: 0xffffff, map: texture,}), new MeshStandardMaterial({color: 0xffffff, map: texture,})]
                    }).then(() => {
                        return group
                    })
                }),
                this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor2_RobotArmAnimation1.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this.floor2.floor2RobotArms[0] = {model: group, animation}

                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor2_RobotArm_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        group.traverse((element) => {
                            const auxVariable = element as Mesh
                            if (!auxVariable.material) return
                            if (element.name === 'Robot_Arm_Fix') {
                                getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor2_RobotArmFIX.png').then((fixedArm: Texture) => {
                                    fixedArm.encoding = sRGBEncoding
                                    const material = new MeshStandardMaterial({color: 0xffffff, map: fixedArm,})
                                    if (Array.isArray(auxVariable.material)) {
                                        const newMaterialArray: Material[] = []
                                        auxVariable.material.forEach(() => {
                                            newMaterialArray.push(material)
                                        })
                                        auxVariable.material = newMaterialArray
                                    } else {
                                        auxVariable.material = material
                                    }
                                })
                            } else {
                                const material = new MeshStandardMaterial({color: 0xffffff, map: texture,})
                                if (Array.isArray(auxVariable.material)) {
                                    const newMaterialArray: Material[] = []
                                    auxVariable.material.forEach(() => {
                                        newMaterialArray.push(material)
                                    })
                                    auxVariable.material = newMaterialArray
                                } else {
                                    auxVariable.material = material
                                }
                            }
                            if (element.name.includes('Sphere_')) {

                                return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Sphere1.png').then((texture: Texture) => {
                                    texture.encoding = sRGBEncoding
                                    //@ts-ignore
                                    const materialData = {...element.material.toJSON(), ...{color: 0xffffff, map: texture, type: 'MeshStandardMaterial'}}
                                    //@ts-ignore
                                    element.material = new MeshStandardMaterial(materialData)

                                })
                            }
                        })
                        return group
                    }).then(() => {
                        return group
                    })
                }),
                this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor2_RobotArmAnimation2.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this.floor2.floor2RobotArms[1] = {model: group, animation}

                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor2_RobotArm_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        this._traverseGroupAndAssignNewMaterial(group, new MeshStandardMaterial({color: 0xffffff, map: texture,}))
                        group.traverse((child) => {
                            if (child.name.includes('Sphere_')) {
                                return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Sphere2.png').then((texture: Texture) => {
                                    texture.encoding = sRGBEncoding
                                    //@ts-ignore
                                    const materialData = {...child.material.toJSON(), ...{color: 0xffffff, map: texture, type: 'MeshStandardMaterial'}}
                                    //@ts-ignore
                                    child.material = new MeshStandardMaterial(materialData)
                                })
                            }
                        })
                        return group
                    }).then(() => {
                        return group
                    })
                }),
                this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor2_RobotArmAnimation3.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this.floor2.floor2RobotArms[2] = {model: group, animation}

                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor2_RobotArm_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        this._traverseGroupAndAssignNewMaterial(group, new MeshStandardMaterial({color: 0xffffff, map: texture,}))
                        group.traverse((child) => {
                            if (child.name.includes('Sphere_')) {
                                return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Sphere3.png').then((texture: Texture) => {
                                    texture.encoding = sRGBEncoding
                                    //@ts-ignore
                                    const materialData = {...child.material.toJSON(), ...{color: 0xffffff, map: texture, type: 'MeshStandardMaterial'}}
                                    //@ts-ignore
                                    child.material = new MeshStandardMaterial(materialData)
                                })
                            }
                        })
                        return group
                    }).then(() => {
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor2_Spheres1.fbx').then((group: Group) => {
                    return new Promise((resolve) => {
                        const animation = new AnimationController(group)
                        getTexture(libUrl + '/3Dmodels/Animated_Geometries/Sphere1.png').then((texture: Texture) => {
                            texture.encoding = sRGBEncoding
                            //@ts-ignore
                            const materialData = {...group.children[0].material.toJSON(), ...{color: 0xffffff, map: texture, type: 'MeshStandardMaterial'}}
                            //@ts-ignore
                            group.children[0].material = new MeshStandardMaterial(materialData)
                            this.floor2.floor2Spheres[0] = {model: group.children[0], animation}
                        })
                        resolve(group)
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor2_Spheres2.fbx').then((group: Group) => {
                    return new Promise((resolve) => {
                        const animation = new AnimationController(group)
                        getTexture(libUrl + '/3Dmodels/Animated_Geometries/Sphere2.png').then((texture: Texture) => {
                            texture.encoding = sRGBEncoding
                            //@ts-ignore
                            const materialData = {...group.children[0].material.toJSON(), ...{color: 0xffffff, map: texture, type: 'MeshStandardMaterial'}}
                            //@ts-ignore
                            group.children[0].material = new MeshStandardMaterial(materialData)
                            this.floor2.floor2Spheres[1] = {model: group.children[0], animation}
                        })
                        resolve(group)
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor2_Spheres3.fbx').then((group: Group) => {
                    return new Promise((resolve) => {
                        const animation = new AnimationController(group)
                        getTexture(libUrl + '/3Dmodels/Animated_Geometries/Sphere3.png').then((texture: Texture) => {
                            texture.encoding = sRGBEncoding
                            //@ts-ignore
                            const materialData = {...group.children[0].material.toJSON(), ...{color: 0xffffff, map: texture, type: 'MeshStandardMaterial'}}
                            //@ts-ignore
                            group.children[0].material = new MeshStandardMaterial(materialData)
                            this.floor2.floor2Spheres[2] = {model: group.children[0], animation}
                        })
                        resolve(group)
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor3_BigScreen.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    group.traverse((child) => {
                        const wololo = child as Mesh<any, MeshStandardMaterial>
                        if (wololo.material && wololo.material.color) {
                            const standardMaterial = new MeshStandardMaterial({color: 0x33d513, transparent: true, opacity: .4})
                            if (wololo.material.emissive.getHex()) {
                                standardMaterial.emissive.set(wololo.material.emissive)
                                standardMaterial.opacity = 1
                            }
                            wololo.material = standardMaterial
                        }
                    })
                    return group
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor3_ComputerReels.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor03_Computer_Reells_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        this._traverseGroupAndAssignNewMaterial(group, new MeshStandardMaterial({color: 0xffffff, map: texture}))
                        return group
                    }).then(() => {
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor3_LEDs.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    return group
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor3_Screens.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor03_Screens_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        this._traverseGroupAndAssignNewMaterial(group, new MeshStandardMaterial({color: 0xffffff, map: texture}))
                        return group
                    }).then(() => {
                        return group
                    })
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor4_City.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    this._traverseGroupAndAssignNewMaterial(group, new MeshStandardMaterial({color: 0x33d513, transparent: true, opacity: .66}))
                    return group
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Geometries/Floor5_Particles.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    return getTexture(libUrl + '/3Dmodels/Animated_Geometries/Floor05_Particles_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        this._traverseGroupAndAssignNewMaterial(group, new MeshStandardMaterial({color: 0xffffff, map: texture}))
                        return group
                    }).then(() => {
                        return group
                    })
                }), //animated-characters
                this.loader.readFbx(libUrl + '/3Dmodels/Animated_Characters/Floor1_Character.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    const character = group.children[0] as Mesh<any, MeshStandardMaterial>
                    const helmet = group.children[2] as Mesh<any, MeshStandardMaterial>
                    const ipad = group.children[3] as Mesh<any, MeshStandardMaterial>
                    if (!character || !helmet || !ipad) return group
                    getTexture(libUrl + '/3Dmodels/Animated_Characters/Characters_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        character.material = new MeshStandardMaterial({color: 0xffffff, map: texture})
                    })
                    getTexture(libUrl + '/3Dmodels/Animated_Characters/Floor01_character_Helmet_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        helmet.material = new MeshStandardMaterial({color: 0xffffff, map: texture})
                    })
                    getTexture(libUrl + '/3Dmodels/Animated_Characters/Floor01_character_iPad_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        ipad.material = new MeshStandardMaterial({color: 0xffffff, map: texture})
                    })
                    group.name = 'Floor1_Character'
                    return group;
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Characters/Floor3_Character1.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    const character = group.children[0] as Mesh<any, MeshStandardMaterial>
                    if (!character) return group
                    getTexture(libUrl + '/3Dmodels/Animated_Characters/Characters_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        character.material = new MeshStandardMaterial({color: 0xffffff, map: texture})
                    })
                    return group;
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Characters/Floor3_Character2.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    const character = group.children[0] as Mesh<any, MeshStandardMaterial>
                    if (!character) return group
                    getTexture(libUrl + '/3Dmodels/Animated_Characters/Characters_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        character.material = new MeshStandardMaterial({color: 0xffffff, map: texture})
                    })
                    return group;
                }), this.loader.readFbx(libUrl + '/3Dmodels/Animated_Characters/Floor4_Character.fbx').then((group: Group) => {
                    const animation = new AnimationController(group)
                    this._animations.push(animation)
                    group.traverse((child) => {
                        if (child.name === 'Floor4_CharacterVRGoogles') {
                            getTexture(libUrl + '/3Dmodels/Animated_Characters/Floor04_VRGoogles_COLOR.png').then((texture: Texture) => {
                                texture.encoding = sRGBEncoding
                                const materials = [] as MeshStandardMaterial[]
                                //@ts-ignore
                                child.material.forEach(() => {
                                    materials.push(new MeshStandardMaterial({color: 0xffffff, map: texture}))
                                })
                                //@ts-ignore
                                child.material = materials
                            })
                        }
                    })

                    const character = group.children[0] as Mesh<any, MeshStandardMaterial>
                    if (!character) return group
                    getTexture(libUrl + '/3Dmodels/Animated_Characters/Characters_COLOR.png').then((texture: Texture) => {
                        texture.encoding = sRGBEncoding
                        character.material = new MeshStandardMaterial({color: 0xffffff, map: texture})
                    })
                    return group;
                }), //room-centers
                this.loader.readFbx(libUrl + '/3Dmodels/CameraTargetPositionREFS/CamaraTarget_Floor1.fbx').then((group: Group) => {
                    group.visible = false
                    group.name = 'Floor_center_01'
                    return group;
                }), this.loader.readFbx(libUrl + '/3Dmodels/CameraTargetPositionREFS/CamaraTarget_Floor2.fbx').then((group: Group) => {
                    group.visible = false
                    group.name = 'Floor_center_02'
                    return group;
                }), this.loader.readFbx(libUrl + '/3Dmodels/CameraTargetPositionREFS/CamaraTarget_Floor3.fbx').then((group: Group) => {
                    group.visible = false
                    group.name = 'Floor_center_03'
                    return group;
                }), this.loader.readFbx(libUrl + '/3Dmodels/CameraTargetPositionREFS/CamaraTarget_Floor4.fbx').then((group: Group) => {
                    group.visible = false
                    group.name = 'Floor_center_04'
                    return group;
                }), this.loader.readFbx(libUrl + '/3Dmodels/CameraTargetPositionREFS/CamaraTarget_Floor5.fbx').then((group: Group) => {
                    group.visible = false
                    group.name = 'Floor_center_05'
                    return group;
                }),];

            Promise.allSettled(promises).then((e) => {
                e.forEach((promise) => {
                    //@ts-ignore
                    const group = promise.value as Group
                    if (group.name === 'Floor1_Building' || group.name === 'Floor1_geo' || group.name === 'Floor1_Character') {
                        this.firstRoom.add(group)
                    } else {
                        this.allGeometries.add(group)
                    }

                })
                const scalar = .025
                this.allGeometries.scale.multiplyScalar(scalar)
                this.firstRoom.scale.multiplyScalar(scalar)
                this.allGeometries.traverse((child) => {
                    if (child.name === 'Floor_center_01' || child.name === 'Floor_center_02' || child.name === 'Floor_center_03' || child.name === 'Floor_center_04' || child.name === 'Floor_center_05') {
                        this.roomsCenter.push(child.children[0].localToWorld(new Vector3()).multiplyScalar(scalar))
                    }
                })

                this.engine.sceneManager.add(this.allGeometries)
                this.engine.sceneManager.add(this.firstRoom)
                this._animations.forEach(animation => animation.playAll('repeat'))
                const camera = this.engine.sceneManager.cameraManager.getActiveCamera();
                const controls = this.engine.sceneManager.controlsManager.getByCamera(camera) as OrbitControls;

                controls.target.copy(this.roomsCenter[0])
                const cameraPosition = new Vector3(-100, 325, 650)
                camera.position.copy(cameraPosition.clone().multiplyScalar(.05))

                const plane = new Mesh(new PlaneGeometry(50, 50, 100, 100), new MeshStandardMaterial({color: 0xffffff, wireframe: false}))
                plane.rotateX(-(Math.PI / 2))
                this.engine.sceneManager.add(plane)
                const allGeometriesSize = MathUtils.getBoundingSize(this.allGeometries).size
                this.allGeometries.position.y = this.allGeometries.position.y - allGeometriesSize.y

                resolve(true)
            }).catch((reason: string) => reject(reason))
        })
    }

    private startAction() {
        const camera = this.engine.sceneManager.cameraManager.getActiveCamera();
        const controls = this.engine.sceneManager.controlsManager.getByCamera(camera) as OrbitControls;

        const cameraPosition = new Vector3(-650, 650, 650)

        const newCameraPosition = cameraPosition.clone().multiplyScalar(.025)

        new TWEEN.Tween(camera.position)
            .to({
                x: newCameraPosition.x, y: newCameraPosition.y, z: newCameraPosition.z,
            }, 3000)
            .easing(TWEEN.Easing.Cubic.Out)
            .onStart(() => {
            })
            .onUpdate(() => {
                camera.lookAt(this.roomsCenter[0])
                controls.target.copy(this.roomsCenter[0])

            })
            .start()
            .onComplete(() => {
                this.setControls(controls);
                controls.enabled = true
            });
        new TWEEN.Tween(this.allGeometries.position)
            .to({
                x: this.allGeometries.position.x, y: 0, z: this.allGeometries.position.z,
            }, 2000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()
    }
}

class AnimationController {
    private mixer: AnimationMixer;

    public actions: Map<string, AnimationAction>;
    public animations: Map<string, AnimationClip>;

    constructor(data: Object3D) {
        this.mixer = new AnimationMixer(data)
        this.actions = new Map();
        this.animations = new Map();

        this.setActions(data.animations);
        this.setAnimations(data.animations);
    }

    public unPauseAllActions() {
        this.actions.forEach((action) => {
            action.paused = false;
        });
    }

    public pauseAllActions() {
        this.actions.forEach((action) => {
            action.paused = true;
        });
    }

    public playAction(animationName: string) {
        const action = this.actions.get(animationName)
        if (!action) return
        action.play()
    }

    public playAll(loop: 'once' | 'repeat' | 'pingPong' = 'once') {
        const loopTypes = {
            'once': LoopOnce,
            'repeat': LoopRepeat,
            'pingPong': LoopPingPong,
        }
        this.actions.forEach((action) => {
            action.loop = loopTypes[loop]
            action.reset()
            action.play()
        })
    }

    public update(deltaSeconds: number) {
        this.mixer.update(deltaSeconds)
    }

    public setActions(animations: AnimationClip[]) {
        animations.forEach((animation) => {
            this.actions.set(animation.name, this.mixer.clipAction(animation));
        });
    }

    public setAnimations(animations: AnimationClip[]) {
        animations.forEach((animation) => {
            this.animations.set(animation.name, animation);
        });
    }
}
