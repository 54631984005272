import { Group, LoadingManager } from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { IFCModel } from "web-ifc-three/IFC/components/IFCModel";
import { IFCLoader } from "web-ifc-three/IFCLoader";
//
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";
export const ifcLoader = new IFCLoader();
ifcLoader.ifcManager.setWasmPath("../");
ifcLoader.ifcManager.setupThreeMeshBVH(computeBoundsTree, disposeBoundsTree, acceleratedRaycast);

export async function loadIFC(url: string) {
    return await ifcLoader.loadAsync(url);
}

import { IFCSLAB } from "web-ifc";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
export const IFCSLABCore = IFCSLAB;
//

export default class ModelLoader {
    constructor() {}

    async loadFiles(files: FileList): Promise<Group[]> {
        return this.getGroupsFromFiles(files);
    }

    async loadFile(file: File): Promise<Group> {
        return await this.getGroupFromFile(file);
    }
    load(path: string): Promise<Group> {
        const ext = path.split(".").pop() ?? "unknown";
        return this.getGroupFromUrl(ext, path);
    }

    private async getGroupsFromFiles(files: FileList): Promise<Group[]> {
        const groupPromises: Array<Promise<Group>> = [];

        for (let index = 0; index < files.length; index++) {
            const file = files.item(index);
            if (file) groupPromises.push(this.getGroupFromFile(file));
        }

        return Promise.allSettled(groupPromises).then((arrayResult) => {
            const groups: Array<Group> = [];
            arrayResult.forEach((promise) => {
                //@ts-ignore
                if (promise.value) groups.push(promise.value);
            });
            return groups;
        });
    }

    private async getGroupFromFile(file: File): Promise<Group> {
        const ext = file.name.split(".").pop();
        if (ext) {
            const fileUrl = await this.file2Url(file);
            return this.getGroupFromUrl(ext, fileUrl);
        } else {
            throw new Error("Extension no detected.");
        }
    }

    private getGroupFromUrl(extension: string, fileUrl: string): Promise<Group> {
        return new Promise((resolve, reject) => {
            switch (extension) {
                case "fbx":
                    this.readFbx(fileUrl)
                        .then((group) => {
                            resolve(group);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                    break;
                case "gltf":
                    this.readGltf(fileUrl)
                        .then((group) => {
                            resolve(group.scene);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                    break;
                case "ifc":
                    this.readIfc(fileUrl)
                        .then((group) => {
                            //@ts-ignore
                            resolve(group);
                        })
                        .catch((error) => {
                            reject(error);
                        });

                    break;
                default:
                    console.error(`ModelLoader - Error reading file with extension ${extension}, Unknown extension.`);
                    reject("Unknown extension.");
                    break;
            }
        });
    }
    readIfc(ifcUrl: string, onLoad?: (ifc: IFCModel) => void): Promise<IFCModel> {
        const loader = new IFCLoader();
        loader.ifcManager.setWasmPath("../");

        return new Promise((resolve, reject) => {
            loader.load(ifcUrl, (ifcModel) => {
                try {
                    if (onLoad) onLoad(ifcModel);
                    resolve(ifcModel);
                } catch (error) {
                    console.error(`Error loading FBX with url: ${ifcUrl}`);
                    reject(error);
                }
            });
        });
    }
    readFbx(fbxUrl: string, onLoad?: (fbx: Group) => void): Promise<Group> {
        const managerLoader = new LoadingManager();
        const loader = new FBXLoader(managerLoader);

        return new Promise((resolve, reject) => {
            loader.load(
                fbxUrl,
                (fbxGroup) => {
                    try {
                        managerLoader.onProgress = (_url, itemsLoaded, itemsTotal) => {
                            if (itemsLoaded === itemsTotal) {
                                if (onLoad) onLoad(fbxGroup);
                                resolve(fbxGroup);
                            }
                        };
                    } catch (error) {
                        console.error(`ModelLoader -> Error loading FBX with url: ${fbxUrl}`);
                        reject(error);
                    }
                },
                undefined,
                (error) => {
                    console.error(`ModelLoader -> Error loading FBX with url: ${fbxUrl}`);
                    reject(error);
                }
            );
        });
    }
    readObj(objUrl: string, onLoad?: (fbx: Group) => void): Promise<Group> {
        const loader = new OBJLoader();

        return new Promise((resolve, reject) => {
            loader.load(objUrl, (objGroup) => {
                try {
                    if (onLoad) onLoad(objGroup);
                    resolve(objGroup);
                } catch (error) {
                    console.error(`Error loading FBX with url: ${objUrl}`);
                    reject(error);
                }
            });
        });
    }

    readGltf(gltfUrl: string, onLoad?: (gltf: GLTF) => void): Promise<GLTF> {
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
        dracoLoader.setDecoderConfig({ type: "js" });
        loader.setDRACOLoader(dracoLoader);

        return new Promise((resolve, reject) => {
            try {
                loader.load(gltfUrl, (gltfLoaded) => {
                    if (onLoad) onLoad(gltfLoaded);
                    resolve(gltfLoaded);
                });
            } catch (error) {
                reject(error);
            }
        });
    }
    readGltf2(gltfUrl: string, onLoad?: (gltf: GLTF) => void) /*: Promise<GLTF> */ {
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
        dracoLoader.setDecoderConfig({ type: "js" });
        loader.setDRACOLoader(dracoLoader);

        return new Promise((resolve, reject) => {
            try {
                console.time("answer time");
                console.timeLog("answer time");
                
                const reader = new XMLHttpRequest();
                reader.onload = (ev) => {
                    console.log("--------------");
                    console.timeLog("answer time");
                    loader.parse(reader.responseText, gltfUrl, (gltf) => {
                        console.timeEnd("answer time");
                        console.log(gltf);
                    });
                };
                reader.onprogress = (ev) => {
                    console.log("z");
                    //console.log(ev);
                };
                reader.open("GET", gltfUrl);
                reader.send();
            } catch (error) {
                reject(error);
            }
        });
    }

    file2Url(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                this.file2Blob(file).then((blob) => {
                    resolve(URL.createObjectURL(blob));
                });
            } catch (error) {
                console.error(`Error creating URL for file: "${file.name}"`);
                reject(error);
            }
        });
    }

    file2Blob(file: File): Promise<Blob> {
        return new Promise((resolve, reject) => {
            try {
                file.arrayBuffer().then((e: ArrayBuffer) => {
                    resolve(new Blob([e], { type: file.type }));
                });
            } catch (error) {
                console.error(`Error reading file: "${file.name}"`);
                reject(error);
            }
        });
    }
}
