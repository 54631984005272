import AbstractManager from "@/itw-core/AbstractManager";
import * as THREE from "three";
import { Material, MeshStandardMaterial } from "three";
import { Helper } from "./utils/Helper";

export default class MaterialManager extends AbstractManager {
    private properties = new Set([
        "alphaMap",
        "aoMap",
        "bumpMap",
        "map",
        "displacementMap",
        "emissiveMap",
        "envMap",
        "lightMap",
        "metalnessMap",
        "normalMap",
        "roughnessMap",
        "color",
        "emissive",
        "aoMapIntensity",
        "bumpScale",
        "displacementScale",
        "displacementBias",
        "emissiveIntensity",
        "envMapIntensity",
        "lightMapIntensity",
        "metalness",
        "normalMapType",
        "roughness",
        "wireframeLinewidth",
        "flatShading",
        "fog",
        "wireframe",
        "wireframeLinecap",
        "wireframeLinejoin",
        "normalScale",
    ]);
    public types = {
        textureType: new Set([
            "alphaMap",
            "aoMap",
            "bumpMap",
            "map",
            "displacementMap",
            "emissiveMap",
            "envMap",
            "lightMap",
            "metalnessMap",
            "normalMap",
            "roughnessMap",
        ]),
        colorType: new Set(["color", "emissive"]),
        numberType: new Set([
            "aoMapIntensity",
            "bumpScale",
            "displacementScale",
            "displacementBias",
            "emissiveIntensity",
            "envMapIntensity",
            "lightMapIntensity",
            "metalness",
            "normalMapType",
            "roughness",
            "wireframeLinewidth",
        ]),
        booleanType: new Set(["flatShading", "fog", "wireframe"]),
        stringType: new Set(["wireframeLinecap", "wireframeLinejoin"]),
        vectorType: new Set(["normalScale"]),
    };
    //region AbstractManager[...]
    getName(): string {
        return "MaterialManager";
    }

    initialize(): void {
        //this.addEvents();
    }

    //endregion

    setColor(color: THREE.Color | number, element: THREE.Object3D) {
        if (typeof color === "number") color = new THREE.Color(color);
        //@ts-ignore
        element.material.color = color;
    }

    resetMaterial(material: MeshStandardMaterial) {
        const newConf = Helper.createMeshStandardMaterial({ color: 0xffffff });

        this.properties.forEach((a, b) => {
            //@ts-ignore
            material[a] = newConf[a];
        });
        material.needsUpdate = true;
        this.dispatch("materialChanged", undefined);
    }

    modifyMaterial(materialsUpdate: MaterialUpdate[]) {
        materialsUpdate.forEach((materialUpdate) => {
            const { materialType, element, texture, color, range, enabled, value, vector } = materialUpdate;

            if (texture) {
                //@ts-ignore
                element.material[materialType] = texture;
                //@ts-ignore
                texture.needsUpdate = true;
                //@ts-ignore
                element.material.needsUpdate = true;
            }

            //@ts-ignore
            color ? (element.material[materialType] = color) : "";
            //@ts-ignore
            range ? (element.material[materialType] = range) : "";

            enabled != undefined
                ? //@ts-ignore
                  (element.material[materialType] = enabled)
                : "";

            //@ts-ignore
            value ? (element.material[materialType] = value) : "";

            //@ts-ignore
            vector ? (element.material[materialType] = vector) : "";
        });

        this.dispatch("materialChanged", undefined);
    }

    modifyMaterialNewVer(conf: MaterialApiConfiguration, material: THREE.MeshStandardMaterial) {
        for (const [k, v] of Object.entries(conf)) {
            if (this.properties.has(k)) {
                //@ts-ignore
                material[k] = v;

                if (this.types.textureType.has(k)) {
                    //@ts-ignore
                    v ? (v.needsUpdate = true) : (material[k] = undefined);
                    material.needsUpdate = true;
                }
            }
        }
        this.dispatch("materialChanged", undefined);
    }

    getNewConfig(): MaterialApiConfiguration {
        return {
            alphaMap: null,
            aoMap: null,
            aoMapIntensity: null,
            bumpMap: null,
            bumpScale: null,
            clearcoat: null,
            clearcoatMap: null,
            clearcoatNormalMap: null,
            clearcoatNormalScale: null,
            clearcoatRoughness: null,
            clearcoatRoughnessMap: null,
            color: null,
            displacementBias: null,
            displacementMap: null,
            displacementScale: null,
            emissive: null,
            emissiveIntensity: null,
            emissiveMap: null,
            lightMap: null,
            lightMapIntensity: null,
            map: null,
            metalness: null,
            metalnessMap: null,
            normalMap: null,
            normalMapType: null,
            normalScale: null,
            opacity: null,
            roughness: null,
            roughnessMap: null,
        };
    }

    removeMaterialPropertie(propertieConf: MaterialRemovePropertie) {
        //@ts-ignore
        propertieConf.material[propertieConf.propName] = undefined;
        propertieConf.material.needsUpdate = true;
    }
}

/* export type MeshStandarMaterialConf = {
    alphaMap?: THREE.Texture;
    aoMap?: THREE.Texture;
    aoMapIntensity?: number; //def = 1
    bumpMap?: THREE.Texture;
    bumpScale?: number; //0 to 1 deff = 1
    map?: THREE.Texture;
    color?: THREE.Color; //def = 0xffffff
    defines?: any; //An object of the form: { 'STANDARD': '' };
    displacementMap?: THREE.Texture;
    displacementScale?: number; //def = 1
    displacementBias?: number; //def = 0
    emissive?: THREE.Color;
    emissiveMap?: THREE.Texture;
    emissiveIntensity?: number; //def = 1
    //To ensure a physically correct rendering, you should only add environment
    //maps which were preprocessed by PMREMGenerator. Default is null.
    envMap?: THREE.Texture;
    envMapIntensity?: number; //no defined
    flatShading?: boolean; //def = false
    fog?: boolean; //def = true
    lightMap?: THREE.Texture;
    lightMapIntensity?: number; //def = 1
    metalness?: number;
    metalnessMap?: THREE.Texture;
    normalMap?: THREE.Texture;
    normalMapType?: number; //Options are THREE.TangentSpaceNormalMap (default), and THREE.ObjectSpaceNormalMap.
    normalScale?: THREE.Vector2; //def = (1,1)
    roughness?: number;
    roughnessMap?: THREE.Texture;
    wireframe?: boolean; //def = false
    wireframeLinecap?: string; //Possible values are "butt", "round" and "square". Default is 'round'.
    wireframeLinejoin?: string; //Possible values are "round", "bevel" and "miter". Default is 'round'.
    //Due to limitations of the OpenGL Core Profile with the WebGL renderer on most platforms linewidth will always be 1 regardless of the set value.
    wireframeLinewidth?: number; //def = 1
}; */

export type MaterialUpdate = {
    materialType: string;
    element: THREE.Mesh | THREE.Object3D<THREE.Event>;
    texture?: THREE.Texture;
    color?: THREE.Color;
    range?: number;
    enabled?: boolean;
    value?: string;
    vector?: THREE.Vector2 | THREE.Vector3;
};

export type MaterialRemovePropertie = {
    material: Material;
    propName: string;
};

export type MaterialApiConfiguration = {
    alphaMap: null | THREE.Texture;
    aoMap: null | THREE.Texture;
    aoMapIntensity: null | number;
    bumpMap: null | THREE.Texture;
    bumpScale: null | number;
    clearcoat: null | any;
    clearcoatMap: null | THREE.Texture;
    clearcoatNormalMap: null | THREE.Texture;
    clearcoatNormalScale: null | any;
    clearcoatRoughness: null | any;
    clearcoatRoughnessMap: null | THREE.Texture;
    color: null | THREE.Color;
    displacementBias: null | number;
    displacementMap: null | THREE.Texture;
    displacementScale: null | number;
    emissive: null | THREE.Color;
    emissiveIntensity: null | number;
    emissiveMap: null | THREE.Texture;
    lightMap: null | THREE.Texture;
    lightMapIntensity: null | number;
    map: null | THREE.Texture;
    metalness: null | number;
    metalnessMap: null | THREE.Texture;
    normalMap: null | THREE.Texture;
    normalMapType: null | number;
    normalScale: null | THREE.Vector2;
    opacity: null | number;
    roughness: null | number;
    roughnessMap: null | THREE.Texture;
};
