import AbstractManager from "@/itw-core/AbstractManager";
import * as THREE from "three";
import SceneManager from "@/itw-core/SceneManager";

export default class LightManager extends AbstractManager {
    public ambientLight: THREE.AmbientLight;
    public lights: Array<THREE.Light> = [];
    public lightsStatus: LightsEnabledStatus;

    // @ts-ignore
    private _sceneManager: SceneManager;
    constructor(sceneManager: SceneManager) {
        super();
        this.lightsStatus = {
            AmbientLight: true,
            AmbientLightProbe: true,
            DirectionalLight: true,
            HemisphereLight: true,
            HemisphereLightProbe: true,
            Light: true,
            LightProbe: true,
            PointLight: true,
            RectAreaLight: true,
            SpotLight: true,
        };
        this._sceneManager = sceneManager;
        this.ambientLight = new THREE.AmbientLight();
    }

    addLight(light: THREE.Light) {
        this.lights.push(light);
        this._sceneManager.add(light);
    }

    getName(): string {
        return "LightManager";
    }
    addDefaultDirectionalLight(position?: THREE.Vector3, intensity?: number) {
        if (!position) {
            position = new THREE.Vector3(0, 10, 10);
        }

        intensity = intensity ?? 0.2;

        this.lightsStatus.DirectionalLight ? "" : (intensity = 0);

        const light = new THREE.DirectionalLight(0xffffff, intensity);
        light.castShadow = true;
        light.shadow.mapSize.set(1024 * 2, 1024 * 2);
        light.shadow.bias = -0.0001;
        light.shadow.radius = 1;
        light.shadow.camera.top = 20;
        light.shadow.camera.bottom = -20;
        light.shadow.camera.left = 20;
        light.shadow.camera.right = -20;
        light.position.copy(position);
        this.addLight(light);
    }
    initialize(args?: LightManagerIntializeOptions): void {
        const { ambientLightOptions } = Object.assign(DefaultLightManagerInitializeOptions, args);
        this.ambientLight.intensity = ambientLightOptions.intensity;
        this.ambientLight.color =
            typeof ambientLightOptions.color == "number"
                ? new THREE.Color(ambientLightOptions.color)
                : ambientLightOptions.color;
    }
}
type LightManagerIntializeOptions = {
    ambientLightOptions?: AmbientLightOptions;
};
type AmbientLightOptions = {
    color: THREE.Color | number;
    intensity: number;
};
const DefaultLightManagerInitializeOptions = {
    ambientLightOptions: {
        color: 0xffffff,
        intensity: 0.8,
    },
};

type LightsEnabledStatus = {
    AmbientLight: boolean;
    AmbientLightProbe: boolean;
    DirectionalLight: boolean;
    HemisphereLight: boolean;
    HemisphereLightProbe: boolean;
    Light: boolean;
    LightProbe: boolean;
    PointLight: boolean;
    RectAreaLight: boolean;
    SpotLight: boolean;
};
