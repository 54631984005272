import * as THREE from "three";
import AbstractManager from "@/itw-core/AbstractManager";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

export default class ControlsManager extends AbstractManager {

    public orbitControls: Map<THREE.Camera, OrbitControls> = new Map();
    // @ts-ignore
    private _domElement: HTMLElement;

    constructor() {
        super();
    }

    getName(): string {
        return "ControlsManager";
    }

    initialize(options: ControlsManagerInitializeOptions): void {
        const {domElement} = options;
        this._domElement = domElement;
    }

    getByCamera(camera: THREE.Camera) {
        return this.orbitControls.get(camera);
    }

    getAutoRotation(camera:THREE.Camera):boolean{
        return this.getByCamera(camera)?.autoRotate ?? false
    }

    toggleRotation(camera:THREE.Camera){
        const controls = this.getByCamera(camera)
        if (controls) {
            controls.autoRotate = !controls.autoRotate;
            return controls.autoRotate;
        } else {
            return false;
        }
    }

    attachToCamera(camera: THREE.Camera) {
        const oc = new OrbitControls(camera, this._domElement);
        oc.listenToKeyEvents(this._domElement)
        oc.target.copy(new THREE.Vector3(0, 0, 0))
        this.orbitControls.set(camera, oc);
    }

    setControlsTarget(orbitControls: OrbitControls, target: THREE.Vector3) {
        orbitControls.target.copy(target)
    }
}
export type ControlsManagerInitializeOptions = {
    domElement: HTMLElement
}