import { remove } from "@vue/shared";
import { Vector3 } from "three";
//@ts-ignore
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";

export const MovementHelper = {
    smoothMoveto(options: {
        from: Vector3;
        to: Vector3;
        duration: number;
        onComplete?: () => void;
        onUpdate?: () => void;
    }) {
        const { duration, from, to, onComplete, onUpdate } = options;
        const tween = new TWEEN.Tween(from)
            .to(
                {
                    x: to.x,
                    y: to.y,
                    z: to.z,
                },
                duration ?? 1000
            )
            .easing(TWEEN.Easing.Cubic.Out)
            .onUpdate(() => {
                //camera.lookAt(camera.position.x + 2, camera.position.y - 1, camera.position.z - 2);
                if(onUpdate)onUpdate()
            })
            .start() // define delay, easing
            .onComplete(() => {
                /* controls.enabled = true; //enable orbit controls
                    this.engine.sceneManager.controlsManager.setControlsTarget(controls, center);
                    remove(this.tween); // remove the animation from memory */
                    if(onComplete)onComplete()
                TWEEN.remove(tween);
            });
    },
};
