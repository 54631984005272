import * as THREE from "three";
import { Helper } from "@/itw-core/utils/Helper";

export const MathUtils = {
    /**
     * @param element
     */
    getBoundingSize(element: THREE.Object3D): BoundingSize {
        const box = Helper.createBox(element);
        const size = new THREE.Vector3(
            Math.abs(box.max.x - box.min.x),
            Math.abs(box.max.y - box.min.y),
            Math.abs(box.max.z - box.min.z)
        );
        return {
            size: size,
            bounds: box,
        };
    },
    getSize(element: THREE.Object3D): THREE.Vector3 {
        const box = Helper.createBox(element);
        const size = new THREE.Vector3(
            Math.abs(box.max.x - box.min.x),
            Math.abs(box.max.y - box.min.y),
            Math.abs(box.max.z - box.min.z)
        );
        return size;
    },
    getObjectCenter(element: THREE.Object3D) {
        const size = this.getBoundingSize(element);
        const coor = {
            x: size.bounds.max.x - size.size.x / 2,
            y: size.bounds.max.y - size.size.y / 2,
            z: size.bounds.max.z - size.size.z / 2,
        };

        return new THREE.Vector3(coor.x, coor.y, coor.z);
    },
    /**
     *
     * @param element
     */
    getCenterRelativeToScene(element: THREE.Object3D): THREE.Vector3 {
        const boundingSize = this.getBoundingSize(element);
        const coordenates = new THREE.Vector3(0, 0, 0);
        coordenates.x = element.position.x + (boundingSize.size.x / 2 - boundingSize.bounds.max.x);
        coordenates.y = element.position.y + (boundingSize.size.y - boundingSize.bounds.max.y);
        coordenates.z = element.position.z + (boundingSize.size.z / 2 - boundingSize.bounds.max.z);
        return coordenates;
    },
    cameraDistanceFromObject(element: THREE.Object3D, fov: number, aspect:number) {
        /* const { size, bounds } = this.getBoundingSize(element);
        const cameraDistance =
            Math.max(size.x, size.y) / 2 / (Math.tan(fov / 2) < 0 ? -1 * Math.tan(fov / 2) : Math.tan(fov / 2));

        return new THREE.Vector3(bounds.max.x - size.x / 2, bounds.max.y - size.y / 2, cameraDistance); */
        const {size, bounds} = this.getBoundingSize(element)

        //Indio
        const max = Math.max(size.x, size.y);
        const dH = max / (2 * Math.atan((Math.PI * fov) / 360));
        const dW = dH / aspect;
        const cameraDistance = Math.max(dH, dW)+ 1.5;

        return new THREE.Vector3(bounds.max.x - size.x / 2, bounds.max.y - size.y / 2, cameraDistance);
    },
};

//@todo Move

type BoundingSize = {
    size: THREE.Vector3;
    bounds: THREE.Box3;
};
