
import {defineComponent} from 'vue';
import SceneComponent from './components/SceneComponent.vue';

export default defineComponent({
    name: 'App',
    components: {
        SceneComponent
    }
});
