import SceneManager from "@/itw-core/SceneManager";
import AbstractManager from "@/itw-core/AbstractManager";
import {Clock} from "three";
//@ts-ignore
import {TWEEN} from "three/examples/jsm/libs/tween.module.min.js";

//@ts-ignore
//import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js";
//@ts-ignore
//import { RenderPass } from "three/addons/postprocessing/RenderPass.js";
//@ts-ignore
import {ShaderPass} from "three/addons/postprocessing/ShaderPass.js";
import AnimatedModel from "./classes/AnimatedModel";
import {Helper} from "./utils/Helper";

//@ts-ignore
//import { OutlinePass } from "three/addons/postprocessing/OutlinePass.js";

export default class EngineManager extends AbstractManager {
    //@ts-ignore
    public viewport: HTMLElement;
    public sceneManager: SceneManager;

    /* public composer: EffectComposer;
    public outlineOver: OutlinePass;
    public outlineSelected: OutlinePass; */
    public effectFXAA: ShaderPass;
    public postProcesingUse: boolean;

    //test
    public tween: TWEEN;

    //animated-models
    public animatedModels: Map<string, AnimatedModel>;
    public clock: Clock;
    public delta: number
    public clockAnimation: Clock;
    public deltaAnimation: number
    public intervalAnimation: number = 1 / 30

    constructor(postProcesing = false) {
        super();
        this.sceneManager = new SceneManager();
        this.postProcesingUse = postProcesing;
        this.animatedModels = new Map();

        this.clock = new Clock();
        this.delta = 0.1
        this.clockAnimation = new Clock();
        this.deltaAnimation = 0
    }

    getName(): string {
        return "";
    }

    /* onPointerMove(outliner: OutlinePass): void {
        const intersects = this._calculateRay();
        if (intersects.length > 0) {
            outliner.selectedObjects = [intersects[0].object];
        } else {
            outliner.selectedObjects = [];
        }
    }
    onPointerDown(outliner: OutlinePass): void {
        const intersects = this._calculateRay();
        if (intersects.length > 0) {
            outliner.selectedObjects = [intersects[0].object];
            this.dispatch("modelSelected", intersects[0].object);
        } else {
            outliner.selectedObjects = [];
        }
    } */

    public _calculateRay() {
        const cameraScope = this.sceneManager.cameraManager;
        const sceneScope = this.sceneManager;
        return cameraScope.rayCastManager.clickScene(sceneScope.sceneElements["Mesh"]);
    }

    initialize(args: string): void {
        this.viewport = document.querySelector(args) ?? document.body;
        this.sceneManager.initialize(this.viewport);
        this.viewport.appendChild(this.sceneManager.renderManager.viewport);

        //if(this.postProcesingUse)this.postProcesing();

        // eslint-disable-next-line
        const scope = this;
        this.clock.getDelta()
        this.animate(scope);
    }

    /* postProcesing() {
        const renderer = this.sceneManager.renderManager.renderer;
        const scene = this.sceneManager.scene;
        const camera = this.sceneManager.cameraManager.getActiveCamera();

        renderer.domElement.style.touchAction = "none";

        renderer.domElement.addEventListener("pointermove", () => {
            this.onPointerMove(this.outlineOver);
        });
        renderer.domElement.addEventListener("pointerdown", () => {
            this.onPointerDown(this.outlineSelected);
        });

        this.composer = new EffectComposer(renderer);
        const renderPass = new RenderPass(scene, camera);
        this.composer.addPass(renderPass);
        this.outlineOver = new OutlinePass(
            new THREE.Vector2(this.viewport.offsetWidth, this.viewport.offsetHeight),
            scene,
            camera
        );
        this.outlineSelected = new OutlinePass(
            new THREE.Vector2(this.viewport.offsetWidth, this.viewport.offsetHeight),
            scene,
            camera
        );
        this.composer.addPass(this.outlineOver);
        this.composer.addPass(this.outlineSelected);
        this.outlineSelected.edgeGlow = 10;
        this.outlineSelected.edgeStrength = 10;
        this.outlineSelected.edgeThickness = 10;
    } */

    animate(scope: EngineManager, requestFrame: boolean = true) {
        scope.delta = scope.clock.getDelta();

        scope.dispatch("onFrame", null);

        const aux = scope.clockAnimation.getDelta()
        scope.deltaAnimation += aux

        if (scope.deltaAnimation > scope.intervalAnimation) {
            scope.deltaAnimation = scope.deltaAnimation % scope.intervalAnimation
        }

        /* if (scope.composer) {
            scope.composer.render(0.01);
        } else { */
        scope.sceneManager.renderManager.renderer.render(scope.sceneManager.scene, scope.sceneManager.cameraManager.getActiveCamera());
        //}

        const orbitControls = scope.sceneManager.controlsManager.getByCamera(scope.sceneManager.cameraManager.getActiveCamera());
        if (orbitControls) orbitControls.update();

        if (requestFrame) requestAnimationFrame(() => {
            scope.animate(scope);
        });

        TWEEN.update(); // update animations
        //model-animations
        this.animatedModels.forEach((animationModel) => {
            animationModel.update();
        });
    }

    onFrame(callback: () => void) {
        this.addEventListener("onFrame", callback);
    }

    async addAnimatedModel(gltfUrl: string, deltaTime: number) {
        const newModel = new AnimatedModel(gltfUrl, deltaTime);

        await newModel.init(gltfUrl);
        if (newModel.animations.size !== 0) {
            const id: string = Helper.generateRandomId();
            this.animatedModels.set(id, newModel);

            return {id: id, model: newModel};
        }

        return undefined;
    }
}
