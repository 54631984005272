import {Texture, TextureLoader} from "three";
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

/* export function readFile2Url(file: File):Promise<String> {
    return new Promise((resolve, reject) => {
        try {
            file.arrayBuffer().then((e: ArrayBuffer) => {
                const imageBlob = new Blob([e], { type: file.type });
                resolve(URL.createObjectURL(imageBlob));
            });
        } catch (error) {
            console.error(`Error reading file: "${file.name}"`);
            console.error(error);
            reject(error);
        }
    });
} */

export function readFile2Url(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            readFile2Blob(file).then((blob) => {
                resolve(URL.createObjectURL(blob));
            });
        } catch (error) {
            console.error(`Error creating URL for file: "${file.name}"`);
            console.error(error);
            reject(error);
        }
    });
}

export function readFile2UrlAndBlob(file: File): Promise<{ url: string; blob: Blob }> {
    return new Promise((resolve, reject) => {
        try {
            readFile2Blob(file).then((blob) => {
                resolve({url: URL.createObjectURL(blob), blob: blob});
            });
        } catch (error) {
            console.error(`Error creating URL for file: "${file.name}"`);
            console.error(error);
            reject(error);
        }
    });
}

export function readFile2Blob(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
        try {
            file.arrayBuffer().then((e: ArrayBuffer) => {
                const imageBlob = new Blob([e], {type: file.type});
                resolve(imageBlob);
            });
        } catch (error) {
            console.error(`Error reading file: "${file.name}"`);
            console.error(error);
            reject(error);
        }
    });
}

export function blob2Base64(blob: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        try {
            const loader = new FileReader();

            blob ? loader.readAsDataURL(blob) : "";

            loader.onloadend = function () {
                resolve(loader.result);
            };
        } catch (error) {
            console.error(`Error reading Blob: "${Blob.name}"`);
            console.error(error);
            reject(error);
        }
    });
}

export function loadGLTF(url: string, onLoad?: (gltf: GLTF) => void, onProgress?: (event: ProgressEvent) => void, onError?: (event: ErrorEvent) => void): Promise<GLTF> {
    return new Promise((resolve, reject) => {
        const loader = new GLTFLoader();

        loader.load(url, (gltf) => {
            onLoad ? onLoad(gltf) : "";
            resolve(gltf);
        }, (event) => {
            onProgress ? onProgress(event) : "";
        }, (event) => {
            onError ? onError(event) : "";
            reject(event);
        });
    });
}

export function getTexture(imgUrl: string): Promise<Texture> {
    return new Promise((resolve, reject) => {
        new TextureLoader()
            .loadAsync(imgUrl)
            .then((texture: Texture) => {
                resolve(texture);
            })
            .catch((error) => {
                reject(error);
            });
    })
}